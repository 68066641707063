@import "./variables";

.fb-pain-diagram {
  background-repeat: no-repeat;
  background-size: cover;

  &.fullBody {
    background-image: $fullBodyImage;
  }

  &.footAndAnkle {
    background-image: $footAndAnkle;
  }
}
